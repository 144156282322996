import React, { useCallback, useRef } from 'react';
import { Box, Container, InputAdornment, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import Captcha from 'react-google-recaptcha';
import searchNormal from 'assets/icons/searchNormal.svg';
import NCCER_logo_wordmark_positive_RGB from 'assets/images/nccer/NCCER_logo_wordmark_positive_RGB.png';
import { tenantConfig } from 'config';
import routes from 'store/configs/Routes';
import styles from './OnlineVerification.module.scss';

const OnlineVerification: React.FunctionComponent = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const captchaRef = useRef<Captcha>(null);
  const [searchVal, setSearchVal] = React.useState('');

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      // Input validation.
      if (!searchVal) {
        enqueueSnackbar('Please provide a search parameter.', { variant: 'error' });
        return;
      }
      try {
        // Reset captcha if existing.
        if (captchaRef.current?.getValue()) captchaRef.current.reset();
        // Get captcha token.
        const token = (await captchaRef.current?.executeAsync()) ?? '';
        history.push(`${routes.ovSearch}/${searchVal}?token=${token}`);
      } catch (error) {
        enqueueSnackbar('Unable to get captcha token. Please refresh the page.', { variant: 'error' });
      }
    },
    [searchVal, history, enqueueSnackbar]
  );

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(event.currentTarget.value);
  };

  return (
    <div className={styles.ovContent}>
      <img src={NCCER_logo_wordmark_positive_RGB} />
      <Typography variant={'h1'} className={styles.unsearchedTitle}>
        {'Credential Search'}
      </Typography>
      <Typography variant={'h5'} className={styles.unsearchedDescription}>
        {"Search and verify individuals' credentials with their NCCER Number OR their Last Name and SSN"}
      </Typography>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={2}>
            <TextField
              placeholder={'12345678 or Smith 123-45-6789'}
              value={searchVal}
              size={'small'}
              onChange={handleSearchInput}
              className={styles.searchBar}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <img src={searchNormal} />
                  </InputAdornment>
                ),
              }}
            />
            <Captcha ref={captchaRef} sitekey={tenantConfig.captchaSiteKey} size="invisible" />
          </Box>
        </form>
      </Container>
    </div>
  );
};
export default OnlineVerification;
